import * as React from 'react';
import { ProgressBar, Card, CardHeader, CardBody } from '@vulcan/vulcan-materialui-theme';
import { IconResources } from 'src/Shared/Constants';
import IconButton from './IconButton';
import HomePageStore from './HomePageStore';
import SettingStore from '../App/Layout/SettingStore';
import { inject, observer } from 'mobx-react';
import { Link } from 'src/App/Infrastructure';
import { SecurityStore } from 'src/App/Auth';

interface Props {
    homePageStore?: HomePageStore;
    settingStore?: SettingStore;
    securityStore?: SecurityStore;
}

@inject('homePageStore', 'settingStore', 'securityStore')
@observer
export class HomePageLayout extends React.Component<Props> {

    public async UNSAFE_componentWillMount(): Promise<void> {
        document.title = 'Home Page - IT Portal';
        await this.props.homePageStore!.init();
    }

    private onClick = (): void => {
        window.open(this.props.homePageStore!.statusPageUrl, '_blank');
    };

    private filterItOnlyUrls(urls: Link[], isItLinksActive: boolean) {
        return urls.filter(website => !website.itOnly || (website.itOnly && isItLinksActive));
    }

    public render(): React.ReactNode {
        const store = this.props.homePageStore!;
        const showItOnlyLinks = this.props.settingStore!.itOnlyLinksActive && this.props.securityStore!.isITUser;
        const stagingWebsiteUrls = this.filterItOnlyUrls(this.props.homePageStore!.stagingWebsiteUrls, showItOnlyLinks);
        const stagingApiUrls = this.filterItOnlyUrls(this.props.homePageStore!.stagingApiUrls, showItOnlyLinks);
        const liveWebsiteUrls = this.filterItOnlyUrls(this.props.homePageStore!.liveWebsiteUrls, showItOnlyLinks);
        const liveApiUrls = this.filterItOnlyUrls(this.props.homePageStore!.liveApiUrls, showItOnlyLinks);
        const usefulUrls = this.filterItOnlyUrls(this.props.homePageStore!.usefulUrls, showItOnlyLinks);
        return (
            <div className='container'>
                <ProgressBar showProgressBar={store.loading} />
                <Card className='card home-page-card'>
                    <CardHeader color='primary' icon={true} className='card-header' >
                        <div className='card-header-section'>
                            <h4 className='card-title'>Foundry Status</h4>
                        </div>
                    </CardHeader>
                    {!store.loading && store.statusSummary &&
                        <CardBody>
                            <div className='status-board' style={{ backgroundColor: store.statusSummary.statusBackgroundColor }}>
                                <span>{store.statusSummary.statusTitle}</span>
                            </div>
                            <span onClick={this.onClick}>Click for more information</span>
                        </CardBody>
                    }
                </Card>
                { showItOnlyLinks &&
                    (<Card className='card home-page-card'>
                        <CardHeader color='primary' icon={true} className='card-header' >
                            <div className='card-header-section'>
                                <h4 className='card-title'>Foundry App Services - Staging</h4>
                            </div>
                        </CardHeader>

                        <CardBody>
                            {stagingWebsiteUrls.map(website => (
                                <IconButton
                                    key={website.url + website.text}
                                    imgSrc={website.image}
                                    icon={IconResources[website.icon]}
                                    text={website.text}
                                    link={website.url} />
                            ))}
                            {stagingApiUrls.map(website => (
                                <IconButton
                                    key={website.url + website.text}
                                    imgSrc={website.image}
                                    icon={IconResources[website.icon]}
                                    text={website.text}
                                    link={website.url} />
                            ))}
                        </CardBody>
                    </Card>)
                }
                <Card className='card home-page-card'>
                    <CardHeader color='primary' icon={true} className='card-header' >
                        <div className='card-header-section'>
                            <h4 className='card-title'>Foundry App Services - Production</h4>
                        </div>
                    </CardHeader>

                    <CardBody>
                        {liveWebsiteUrls.map(website => (
                            <IconButton
                                key={website.url + website.text}
                                imgSrc={website.image}
                                icon={IconResources[website.icon]}
                                text={website.text}
                                link={website.url} />
                        ))}
                        {liveApiUrls.map(website => (
                            <IconButton
                                key={website.url + website.text}
                                imgSrc={website.image}
                                icon={IconResources[website.icon]}
                                text={website.text}
                                link={website.url} />
                        ))}
                    </CardBody>
                </Card>

                <Card className='card home-page-card'>
                    <CardHeader color='primary' icon={true} className='card-header' >
                        <div className='card-header-section'>
                            <h4 className='card-title'>Useful Links</h4>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {usefulUrls.map(website => (
                            <IconButton
                                key={website.url + website.text}
                                imgSrc={website.image}
                                icon={IconResources[website.icon]}
                                text={website.text}
                                link={website.url} />
                        ))}
                    </CardBody>
                </Card>
            </div >
        );
    }
}

export default HomePageLayout;
