import { observable, action, runInAction } from 'mobx';
import { RoleResponse } from '@vulcan/security-api-client/lib/models';
import { BaseStore } from '../Infrastructure';
import StringResources from '../../Shared/Constants/StringResources';

export class SecurityStore extends BaseStore {
    @observable public isITUser = false;

    @action public init = async (): Promise<void> => {

        this.setIsLoading(true);
        const userRoles = await this.getUserRoles();
        const isITUser = await this.isUserInITRole(userRoles);
        this.setIsLoading(false);

        runInAction(() => {
            this.isITUser = isITUser;
        });
    };

    public async isUserInITRole(userRoles) {
        return userRoles.find(x => x.roleName.toLowerCase().startsWith(window.appSettings.itRoleName)) ? true : false ;
    }

    private getUserRoles = async (): Promise<RoleResponse[]> => {
        let result: RoleResponse[] = [];
        try {
            const security = await this.getSecurityClient();
            const response = await security.authenticatedUser.getRoles();
            result = JSON.parse(response._response.bodyAsText) as RoleResponse[];
        } catch (e) {
            result = [];
            this.log(StringResources.ErrorRetrievingData, e);
        }

        return result;
    };

}