import Logger from './Logger';
import { observable, action } from 'mobx';
import { TokenCredentials } from '@azure/ms-rest-js';
import StringResources from 'src/Shared/Constants/StringResources';
import { PortalOptions } from '@vulcan/portal-api-client/lib/models';
import { Portal } from '@vulcan/portal-api-client';
import { Security } from '@vulcan/security-api-client';
import { SecurityOptions } from '@vulcan/security-api-client/lib/models';
import AlertType from 'src/Shared/Alert/AlertType';

export class BaseStore implements Logger {
    @observable private _isLoading: boolean;
    @observable public alert: boolean;
    @observable public alertType: AlertType;
    @observable public alertMessage: string;

    constructor() {
        this._isLoading = false;
        this.alert = false;
        this.alertType = AlertType.Success;
        this.alertMessage = '';
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    @action protected setIsLoading(status: boolean): void {
        this._isLoading = status;
    }

    @action public showAlert = (type: AlertType, message: string): void => {
        this.alertType = type;
        this.alertMessage = message;
        this.alert = true;
        // hide the alert after 2 seconds
        setTimeout(() => this.hideAlert(), 2000);
    };

    @action public hideAlert(): void {
        this.alert = false;
    }

    public log(message: string, error?: Error, info?: object): void {
        const customData = { message: message || '', info: info || {} };
        if (window.Raygun) {
            window.Raygun.send(error || new Error('No Error Info'), customData);
        }
    }

    public getSecurityClient = async (): Promise<Security> => {
        if (!window.getToken) {
            this.log(StringResources.NoGetTokenMethod);
            throw new Error(StringResources.UnexpectedError);
        }

        let cachedToken: string | null = null;
        try {
            cachedToken = await window.getToken();
        } catch (error) {
            this.log(StringResources.NoAuthToken, error);
            throw new Error(StringResources.UnexpectedError);
        }

        const securityUrl = window.appSettings.securityApiUrl;

        if (!securityUrl) {
            this.log(StringResources.TenantUrlNotFound, new Error(StringResources.NoGetTokenMethod));
            throw new Error(StringResources.UnexpectedError);
        }

        const options: SecurityOptions = {
            baseUri: securityUrl
        };
        const tokenCred = new TokenCredentials(cachedToken!);
        return new Security(tokenCred, options);
    };


    public getPortalClient = async (): Promise<Portal> => {
        if (!window.getToken) {
            this.log(StringResources.NoGetTokenMethod);
            throw new Error(StringResources.UnexpectedError);
        }

        let cachedToken: string | null = null;
        try {
            cachedToken = await window.getToken();
        } catch (error) {
            this.log(StringResources.NoAuthToken, error);
            throw new Error(StringResources.UnexpectedError);
        }
        const portalUrl = window.appSettings.portalApiUrl;

        if (!portalUrl) {
            this.log(
                StringResources.TenantUrlNotFound,
                new Error(StringResources.TenantUrlNotFound)
            );
            throw new Error(StringResources.UnexpectedError);
        }

        const options: PortalOptions = {
            baseUri: portalUrl
        };
        const tokenCred = new TokenCredentials(cachedToken!);
        return new Portal(tokenCred, options);
    };
}

export default BaseStore;
