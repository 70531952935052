import * as React from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ProgressBar } from '@vulcan/vulcan-materialui-theme';
import StyledLayout from './Layout';
import AppStore from '../AppStore';

interface Props extends RouteComponentProps {
    appStore: AppStore;
}

@observer
class SiteWrapper extends React.Component<Props, {}> {
    public render(): JSX.Element {

        if (!this.props.appStore.isSettingLoaded || !this.props.appStore.authStore.isLoggedIn) {
            return <ProgressBar className='circular-progress-bar-container' showProgressBar={true} type='circular' />;
        }

        return <StyledLayout />;
    }
}

// tslint:disable-next-line:no-any
export default withRouter<any, any>(SiteWrapper);
