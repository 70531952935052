import { BaseStore } from 'src/App/Infrastructure';
import { observable, action, runInAction } from 'mobx';
import RoadmapModel from './Models/RoadmapModel';

export class RoadmapStore extends BaseStore {
    @observable public loading = false;
    @observable public roadmap = new RoadmapModel();

    @action public async init(pathName: string): Promise<void> {
        this.loading = true;

        const areaNames = {
            '/foundry-roadmap': 'Vulcan Dev',
            '/bi-roadmap': 'BI'
        };

        const roadmap = await this.getRoadmaps(areaNames[pathName]);

        roadmap.features = roadmap.features.filter(x => x.status !== 'Done');

        roadmap.features.forEach((feature) => {
            feature.description = this.stripHtml(feature.description);
        });

        runInAction(() => {
            this.roadmap = roadmap;
            this.loading = false;
        });
    }

    private stripHtml(html: string): string {
        if (!html) {
            return '';
        }
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    }

    private async getRoadmaps(area: string): Promise<RoadmapModel> {
        const portalClient = await this.getPortalClient();
        const response = await portalClient.roadmaps.getRoadmapByArea(area);
        const roadmap = JSON.parse(
            response._response.bodyAsText
        ) as RoadmapModel;
        return roadmap;
    }
}
export default RoadmapStore;
