import { action, observable } from 'mobx';
import { BaseStore, AppSettings } from '../Infrastructure';
import { StringResources } from 'src/Shared/Constants';

declare let AuthenticationContext: new (
    arg0: {
        cacheLocation: string;
        clientId: string;
        redirectUri: string;
        instance: string;
        postLogoutRedirectUri: string;
        tenant: string;
    }) => void;

export class AuthStore extends BaseStore {

    @observable public isLoggedIn = false;
    private _appSettings?: AppSettings;

    constructor() {
        super();
    }

    @action public setLoggedInStatus(status: boolean): void {
        this.isLoggedIn = status;
    }

    @action public init(): void {
        if (this.getAuthContext()) {
            return;
        }

        this._appSettings = window.appSettings;

        window.adalInstance = new AuthenticationContext({
            cacheLocation: this._appSettings.cacheLocation,
            clientId: this._appSettings.azureAppId,
            instance: this._appSettings.msLoginUrl,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: this._appSettings.postLogoutRedirectUri,
            tenant: this._appSettings.azureTenantId
        });

        window.adalInstance.isCallback(window.location.hash);
        window.adalInstance.handleWindowCallback();
        window.getToken = this.getToken;
        this.isLoggedIn = false;
    }

    public getAuthContext(): any {
        return window.adalInstance;
    }

    public getToken = async (): Promise<any> => {
        const self = this;

        return new Promise((resolve, reject) => {
            this.getAuthContext().acquireToken(this.getAuthContext().config.clientId, (error: Error, token: string) => {
                if (error) {
                    self.log(StringResources.ErrorAcquiringToken, error);
                    self.logout();
                    reject(error);
                    return;
                }
                resolve(token);
            });
        });
    };

    public async acquireGraphApiToken(callback: Function): Promise<string> {
        if (!this.getUser) {
            return callback('There has been an error aqcuiring Graph API token', null);
        }
        return await this.getAuthContext()
            .acquireToken(this._appSettings ? this._appSettings!.graphApiUrl : '', callback);
    }

    public login(): void {
        if (this.isAuthenticated) {
            return;
        }

        this.getAuthContext().login();
    }

    public logout = (): void => {
        const self = this;
        if (!self.isAuthenticated) {
            return;
        }
        self.getAuthContext().logOut();
        self.setAuthContext(undefined);
        self.isLoggedIn = false;
    };

    public get isAuthenticated(): boolean {
        if (!this.getAuthContext()) {
            return false;
        }
        const cachedToken = this.getAuthContext().getCachedToken(this.getAuthContext().config.clientId);
        return cachedToken !== null;
    }

    public get getUser(): { profile: any; username: string } | null {
        return this.getAuthContext() ? this.getAuthContext().getCachedUser() : null;
    }

    private setAuthContext(adalInstance: any): void {
        window.adalInstance = adalInstance;
    }
}

export default AuthStore;
