import React from 'react';
import { Icon, CardIcon } from '@vulcan/vulcan-materialui-theme';

interface Pros {
    text: string;
    icon?: object;
    link?: string;
    imgSrc?: string;
}

export class IconButton extends React.Component<Pros> {
    private onClick = (): void => {
        if (this.props.link) {
            window.open(this.props.link, '_blank');
        }
    };

    public render(): JSX.Element {
        return (
            <div className='logo-item' onClick={this.onClick}>
                <CardIcon className='icon' color='primary'>
                    {this.props.icon && <Icon icon={this.props.icon} />}
                    {this.props.imgSrc && (
                        <img src={`images/${this.props.imgSrc}.png`} />
                    )}
                </CardIcon>
                <span>{this.props.text}</span>
            </div>
        );
    }
}

export default IconButton;
