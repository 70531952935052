/**
 * This is the best place we have to put this file for now....
 */

import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, Telemetry } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename : ''});
const reactPlugin = new ReactPlugin();

let ai: ApplicationInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '',
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: {history: browserHistory}
        }
    }
});

export const configureApplicationInsights = (user) => {
    ai =  new ApplicationInsights({
        config: {
            instrumentationKey: 'a4820e56-de51-4e91-b641-f195107ccf39',
            connectionString: window.appSettings.applicationInsightsKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: {history: browserHistory}
            },
            enableAutoRouteTracking: true,
            autoTrackPageVisitTime: true
        }
    });

    ai.loadAppInsights();
    // T O D O: Work out what field is meant to be the account Id, emails are unique so they'll work as a user ID.
    ai.setAuthenticatedUserContext(user.userName, undefined, true);
    ai.addTelemetryInitializer(item => {
        if(item.name === Telemetry.PageView.envelopeType
            || item.name === Telemetry.PageViewPerformance.envelopeType) {
            item.baseData!.name = setItemName(item.baseData!.uri);
        }
        return true;
    });
    ai.trackEvent({name: 'Initialized App Insights'});
};

const setItemName = (uri: string) => {
    const pathVals = uri.split('/');
    // we want the last val of the path to determine what name to give the page.
    switch(pathVals[pathVals.length - 1]) {
    case 'index':
        return 'Home Page';
    case 'foundry-roadmap':
        return 'Foundry Roadmap';
    case 'bi-roadmap':
        return 'BI Roadmap';
    default:
        // we're not told where it's coming from, so just say it's somewhere in the website
        return 'Portal Website';
    }
};

const withAppInsights = (Component) => withAITracking(reactPlugin, Component);
export default withAppInsights;
export const appInsights = () => ai;