import * as React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Sidebar, Header, appStyle, Footer } from '@vulcan/vulcan-materialui-theme';
import { withStyles, WithStyles } from '@material-ui/core';
import * as classNames from 'classnames';
import { MainRoutes } from '../Routes';
import SwitchRoutes from '../Routes/RouteRenderer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SettingFooter from './SettingFooter';
import SettingStore from './SettingStore';
import { AuthStore, UserProfileStore, SecurityStore } from '../Auth';
import logo from 'src/Shared/Assets/website-logo.svg';
import image from 'src/Shared/Assets/sidebar-background.jpg';

interface Props extends WithStyles<appStyle>, RouteComponentProps {
    authStore: AuthStore;
    userProfileStore: UserProfileStore;
    settingStore: SettingStore;
    securityStore: SecurityStore;
}

@inject('authStore', 'userProfileStore', 'settingStore', 'securityStore')
@observer
export class Layout extends React.Component<Props, {}> {

    @observable private isMobileOpen = false;

    constructor(props: Props) {
        super(props);
    }

    public componentDidMount = async (): Promise<void> => {
        window.addEventListener('resize', this.resizeFunction);
    };

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.resizeFunction);
    }

    // tslint:disable-next-line:no-any
    public componentDidUpdate(e: any): void {
        if (e.history.location.pathname !== e.location.pathname) {
            if (this.isMobileOpen) {
                this.handleCloseMobile();
            }
        }
    }

    public render(): JSX.Element | null {
        const { classes } = this.props;
        const mainPanel =
            classes.mainPanel +
            ' ' +
            classNames({
                [classes.mainPanelSidebarMini]: this.props.settingStore.miniActive,
                [classes.mainPanelWithPerfectScrollbar]:
                    navigator.platform.indexOf('Win') > -1
            });

        const siteWrapper = classNames(classes.wrapper + ' ' + 'site-container');

        return (
            <div className={siteWrapper}>
                <Sidebar
                    routes={MainRoutes.filter(x => x.renderInSidebar)}
                    logoText='foundry'
                    logo={logo}
                    image={image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.isMobileOpen}
                    color='orange'
                    bgColor='black'
                    miniActive={this.props.settingStore.miniActive}
                    location={this.props.location}
                    settingsRoutes={MainRoutes.filter(x => x.settingsRoute)}
                    showSettings={true}
                    settingFooter={<SettingFooter key='setting-footer' />}
                />
                <div id='site-container-id' className={mainPanel} >
                    <Header
                        handleDrawerToggle={this.handleDrawerToggle}
                        authStore={this.props.authStore}
                        headerMainText={this.props.userProfileStore!.userName}
                        headerSubText={this.props.userProfileStore!.userEmail}
                        showTenants={false}
                    />
                    <div className='site-content-and-footer-container'>
                        <div className={classes.content}>
                            <div className={classes.container}>
                                <SwitchRoutes
                                    routes={MainRoutes}
                                    location={this.props.location || undefined}
                                />
                            </div>
                        </div>
                        <Footer fluid={true} links={[]} />
                    </div>
                </div>
            </div>
        );
    }

    @action private handleDrawerToggle = (): void => {
        this.isMobileOpen = !this.isMobileOpen;
    };

    @action private resizeFunction = (): void => {
        if (window.innerWidth >= 960) {
            this.handleCloseMobile();
        }
    };

    @action private handleCloseMobile = (): void => {
        this.isMobileOpen = false;
    };
}

const StyledLayout = withStyles(appStyle)(Layout);
// tslint:disable-next-line:no-any
const WrappedLayout = withRouter<any, any>(StyledLayout);
export default WrappedLayout;
