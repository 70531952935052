import { RoadmapPerformanceResponse } from '@vulcan/portal-api-client/lib/models';

export class RoadmapPerformanceModel implements RoadmapPerformanceResponse {
    public lastMonth = 0;
    public lastSixMonths = 0;

    constructor(fields?: Partial<RoadmapPerformanceModel>) {
        Object.assign(this, fields);

    }
}

export default RoadmapPerformanceModel;
