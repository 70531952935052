import * as React from 'react';
import { inject, observer } from 'mobx-react';
import RoadmapHeader from 'src/Roadmap/RoadmapHeader';
import RoadmapTable from 'src/Roadmap/RoadmapTable';
import RoadmapStore from 'src/Roadmap/RoadmapStore';
import { RouteComponentProps } from 'react-router-dom';

interface RoadmapProps extends RouteComponentProps {
    roadmapStore?: RoadmapStore;
}

@inject('roadmapStore')
@observer
export class RoadmapLayout extends React.Component<RoadmapProps> {
    public async componentDidMount(): Promise<void> {
        document.title = 'Road Map - IT Portal';
        const pathName = this.props.location.pathname;
        await this.props.roadmapStore!.init(pathName);
    }

    public render(): React.ReactNode {
        return (
            <div className='container'>
                <RoadmapHeader
                    roadmapLoading={this.props.roadmapStore!.loading}
                    model={this.props.roadmapStore!.roadmap.performance}
                />
                {this.props.roadmapStore!.roadmap.features.length ? (
                    <RoadmapTable
                        roadmap={this.props.roadmapStore!.roadmap}
                        key={this.props.roadmapStore!.roadmap.area}
                    />
                ) : null}
            </div>
        );
    }
}

export default RoadmapLayout;
