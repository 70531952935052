import * as React from 'react';
import AppStore from './AppStore';
import { observer, Provider } from 'mobx-react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ErrorBoundary } from '@vulcan/vulcan-materialui-theme';
import SiteWrapper from './Layout/SiteWrapper';

@observer
export class App extends React.Component<{}, {}> {
    private appStore: AppStore;

    constructor(props: {}) {
        super(props);
        this.appStore = new AppStore();
    }

    public componentDidMount = async (): Promise<void> => {
        await this.appStore.loadAppData();
    };

    public render(): React.ReactNode {

        if (!this.appStore.isSettingLoaded || !this.appStore.authStore.isLoggedIn) {
            return null;
        }

        return (
            <ErrorBoundary logError={this.appStore.log}>
                <Provider {...this.appStore.getStores()}>
                    <Router>
                        <Switch>
                            {/* tslint:disable-next-line:jsx-no-lambda */}
                            <Route path='/' render={(): JSX.Element => <SiteWrapper appStore={this.appStore} />} />
                        </Switch>
                    </Router>
                </Provider>
            </ErrorBoundary>
        );
    }
}

export default App;
