import { Link } from './Link';

export interface AppSettings {
    cacheLocation: string;
    systemDataFormat: string;
    displayDateFormat: string;
    graphApiUrl: string;
    msLoginUrl: string;
    postLogoutRedirectUri: string;
    raygunApiKey: string;
    azureAppId: string;
    azureTenantId: string;
    portalApiUrl: string;
    securityApiUrl: string;
    statusPageUrl: string;
    stagingWebsiteUrls: [Link];
    stagingApiUrls: [Link];
    liveWebsiteUrls: [Link];
    liveApiUrls: [Link];
    usefulUrls: [Link];
    itRoleName: string;
    applicationInsightsKey: string;
}

declare global {
    interface Window {
        appSettings: AppSettings;
        isAuthenticated: boolean;
        getToken: Function | null;
        // tslint:disable-next-line:no-any
        adalInstance: any;
        // tslint:disable-next-line:no-any
        Raygun: any;
        // tslint:disable-next-line:no-any
        authenticate: any;
    }
}

window.appSettings = window.appSettings || {};
window.adalInstance = null;
window.getToken = null;
window.Raygun = null;
