import * as React from 'react';
import { RouteModel } from '.';
import { IconResources } from '../../Shared/Constants';
import { Icon } from '@vulcan/vulcan-materialui-theme';
import HomePageLayout from 'src/HomePage/HomePageLayout';
import RoadmapLayout from 'src/Roadmap/RoadmapLayout';

export const MainRoutes: RouteModel[] = [
    {
        redirect: false,
        path: '/index',
        name: 'Home Page',
        renderInSidebar: true,
        settingsRoute: false,
        icon: (): JSX.Element => <Icon size='30' icon={IconResources.TABLE} />,
        component: HomePageLayout
    },
    {
        redirect: false,
        path: '/foundry-roadmap',
        name: 'Foundry Roadmap',
        renderInSidebar: true,
        settingsRoute: false,
        icon: (): JSX.Element => <Icon size='30' icon={IconResources.RoadmapLogo} />,
        component: RoadmapLayout
    },
    {
        redirect: false,
        path: '/bi-roadmap',
        name: 'BI Roadmap',
        renderInSidebar: true,
        settingsRoute: false,
        icon: (): JSX.Element => <Icon size='30' icon={IconResources.RoadmapLogo} />,
        component: RoadmapLayout
    },
    {
        redirect: true,
        path: '/',
        pathTo: '/index',
        name: 'Root Page',
        renderInSidebar: false,
        settingsRoute: false
    }
];
export default MainRoutes;
