import { BaseStore, Link } from 'src/App/Infrastructure';
import { observable, action, runInAction } from 'mobx';
import { StatusPageStatusSummaryResponse } from '@vulcan/portal-api-client/lib/models';
import { StringResources } from 'src/Shared/Constants';
import AlertType from 'src/Shared/Alert/AlertType';

export class HomePageStore extends BaseStore {
    @observable public loading = false;
    @observable public statusSummary!: StatusPageStatusSummaryResponse;
    public statusPageUrl = '';
    public stagingWebsiteUrls: Link[] = [];
    public stagingApiUrls: Link[] = [];
    public liveWebsiteUrls: Link[] = [];
    public liveApiUrls: Link[] = [];
    public usefulUrls: Link[] = [];

    @action public async init(): Promise<void> {
        try {
            this.loading = true;
            const statusPageComponents = await this.getStatusPageStatusSummary();
            this.getUrls();

            runInAction(() => {
                this.statusSummary = statusPageComponents;
            });
        } catch (e) {
            this.log(StringResources.ErrorLoadingStatusPageStatusSummaryException, e);
            this.showAlert(AlertType.Danger, StringResources.ErrorLoadingStatusPageStatusSummaryException);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    private getUrls() {
        this.statusPageUrl = window.appSettings.statusPageUrl;
        this.stagingWebsiteUrls = window.appSettings.stagingWebsiteUrls;
        this.stagingApiUrls = window.appSettings.stagingApiUrls;
        this.liveWebsiteUrls = window.appSettings.liveWebsiteUrls;
        this.liveApiUrls = window.appSettings.liveApiUrls;
        this.usefulUrls = window.appSettings.usefulUrls;
    }

    private async getStatusPageStatusSummary(): Promise<StatusPageStatusSummaryResponse> {
        const portalClient = await this.getPortalClient();
        const response = await portalClient.statusPages.getStatusPageStatusSummary();
        return JSON.parse(response._response.bodyAsText) as StatusPageStatusSummaryResponse;
    }
}
export default HomePageStore;
