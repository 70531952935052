import * as React from 'react';
import { Card, CardHeader, CardBody, ProgressBar } from '@vulcan/vulcan-materialui-theme';
import RoadmapPerformanceModel from './Models/RoadmapPerformanceModel';
import RoadmapPercentage from './RoadmapPercentage';
import { Typography } from '@material-ui/core';

interface Props {
    roadmapLoading: boolean;
    model: RoadmapPerformanceModel;
}

function RoadmapHeader({ roadmapLoading, model }: Props): JSX.Element {
    return (
        <React.Fragment>
            <div className='roadmap-progress-bar'>
                <ProgressBar showProgressBar={roadmapLoading} />
            </div>
            <div className='roadmap-header-container'>
                <Card className='card roadmap-card'>
                    <CardHeader
                        color='primary'
                        icon={true}
                        className='card-header'
                    >
                        <div className='card-header-section'>
                            <h4 className='card-title'>Roadmap</h4>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Typography variant='body1'>
                            The development team works in 2 week sprints with
                            the aim to deliver a feature in every sprint. Below
                            are the planned features on our roadmap.
                        </Typography>
                    </CardBody>
                </Card>
                <Card className='card roadmap-card roadmap-card-difot '>
                    <CardHeader icon='true' className='card-header'>
                        <div className='card-header-section'>
                            <Typography variant='h4' className='card-title'>
                                DIFOT Performance
                            </Typography>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className='roadmap-percentages-container'>
                            <RoadmapPercentage
                                roadmapLoading={roadmapLoading}
                                percentage={model.lastSixMonths}
                                label={'Last 6 Months'}
                            />
                            <RoadmapPercentage
                                roadmapLoading={roadmapLoading}
                                percentage={model.lastMonth}
                                label={'Last Month'}
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default RoadmapHeader;
