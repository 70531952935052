import moment from 'moment';

export function formatDisplayDate(date?: string): string {
    return date
        ? moment(date.includes('T00:00:00') ? date.replace('T00:00:00', '') : date).format(window.appSettings.displayDateFormat)
        : '---';
}

export function tableDateSortMethod(a: string, b: string): number {
    if (a === undefined && b === undefined) {
        return 0;
    } else if (a === undefined) {
        return 1;
    } else if (b === undefined) {
        return -1;
    }
    return moment(formatDisplayDate(a), window.appSettings.displayDateFormat).isBefore(moment(formatDisplayDate(b), window.appSettings.displayDateFormat)) ? 1 : -1;
}
