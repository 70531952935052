import { RoadmapResponse } from '@vulcan/portal-api-client/lib/models';
import RoadmapFeatureModel from './RoadmapFeatureModel';
import RoadmapPerformanceModel from './RoadmapPerformanceModel';

export class RoadmapModel implements RoadmapResponse {
    public area = '';
    public features: RoadmapFeatureModel[] = [];
    public performance = new RoadmapPerformanceModel();

    constructor(fields?: Partial<RoadmapModel>) {
        Object.assign(this, fields);
    }
}

export default RoadmapModel;
