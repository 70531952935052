import * as React from 'react';
import { Card, CardBody } from '@vulcan/vulcan-materialui-theme';
import ReactTable, { CellInfo, ReactTableDefaults, SortingRule } from 'react-table';
import Radio from '@material-ui/core/Radio';
import { formatDisplayDate, tableDateSortMethod } from '../Shared/Utils';
import RoadmapModel from './Models/RoadmapModel';
import * as Cookies from 'js-cookie';
import { Typography } from '@material-ui/core';

interface Props {
    roadmap: RoadmapModel;
}

function RoadmapTable(props: Props): JSX.Element {
    const defaultSortColumn = 'target';
    const sortColumn = Cookies.get('roadmapSortBy')
        ? JSON.parse(Cookies.get('roadmapSortBy'))
        : defaultSortColumn;

    const columns = [
        {
            id: 'workItemNumber',
            Header: 'Number',
            accessor: 'workItemNumber',
            width: 100,
            sortable: true
        },
        {
            id: 'status',
            Header: 'Status',
            accessor: 'status',
            width: 100,
            sortable: true,
            Cell: (cell: CellInfo): JSX.Element => <Typography variant='body1'>{cell.value}</Typography>
        },
        {
            id: 'title',
            Header: 'Title',
            accessor: 'title',
            width: 200,
            sortable: true
        },
        {
            id: 'description',
            Header: 'Description',
            accessor: 'description',
            sortable: false
        },
        {
            id: 'businessAnalyst',
            Header: 'BA',
            accessor: 'businessAnalyst',
            width: 130,
            sortable: true,
            Cell: (cell: CellInfo): JSX.Element => <Typography variant='body1'>{cell.value}</Typography>
        },
        {
            id: defaultSortColumn,
            Header: 'Target Date',
            accessor: 'target',
            width: 130,
            sortable: true,
            Cell: (cell: CellInfo): JSX.Element => <Typography variant='body1'>{formatDisplayDate(cell.value)}</Typography>,
            sortMethod: tableDateSortMethod
        },
        {
            id: 'isOverdue',
            Header: 'Overdue',
            accessor: 'isOverdue',
            width: 130,
            sortable: true,
            Cell: (cellInfo: CellInfo): JSX.Element => <Radio checked={cellInfo.value} />
        }
    ];

    const handleSortChanged = (newSorted: SortingRule[]): void => {
        const updatedSortColumn = newSorted.length
            ? newSorted[0].id
            : defaultSortColumn;
        Cookies.set('roadmapSortBy', JSON.stringify(updatedSortColumn), {
            expires: 7 /* days*/
        });
    };

    return (
        <Card className='card'>
            <CardBody>
                <ReactTable
                    data={props.roadmap.features}
                    columns={columns}
                    column={{
                        ...ReactTableDefaults.column,
                        style: { whiteSpace: 'unset' }
                    }}
                    className='roadmap-table -striped -highlight'
                    showPagination={false}
                    minRows={1}
                    resizable={false}
                    defaultSorted={[{ id: sortColumn, desc: false }]}
                    onSortedChange={handleSortChanged}
                />
            </CardBody>
        </Card>
    );
}

export default RoadmapTable;
