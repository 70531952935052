import { BaseStore } from '../Infrastructure';
import AuthStore from './AuthStore';

export class UserProfileStore extends BaseStore {
    private authStore: AuthStore;

    constructor(authStore: AuthStore) {
        super();
        this.authStore = authStore;
    }

    public get userName(): string {
        return this.authStore && this.authStore.getAuthContext() ?
            this.authStore.getAuthContext().getCachedUser().profile.name : '';
    }

    public get userEmail(): string {
        return this.authStore && this.authStore.getAuthContext() ?
            this.authStore.getAuthContext().getCachedUser().profile.email : '';
    }
}

export default UserProfileStore;
