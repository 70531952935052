export enum StringResources {
    ErrorAcquiringToken = 'Error Acquiring Token',
    ErrorGettingProfilePhoto = 'Error getting profile photo ',
    NoGetTokenMethod = 'getToken method is not attached to the window object',
    UnexpectedError = 'An unexpected error occurred',
    ErrorRetrievingData = 'Error retrieving data',
    NoAuthToken = 'No Auth token was found',
    TenantUrlNotFound = 'Cannot find tenant url',
    ErrorLoadingStatusPageStatusSummaryException = 'Failed to load status summary.'
}

export default StringResources;
