import * as React from 'react';
import { Typography } from '@material-ui/core';

interface Props {
    roadmapLoading: boolean;
    percentage: number;
    label: string;
}

function RoadmapPercentage({ roadmapLoading, percentage, label }: Props): JSX.Element {
    return (
        <div className='roadmap-percentage-container'>
            <Typography
                variant='h3'
                color={!roadmapLoading && percentage < 70 ? 'error' : 'primary'}
            >
                {roadmapLoading && percentage === 0
                    ? '...'
                    : `${percentage.toFixed(0)}%`}
            </Typography>
            <Typography variant='caption'>{label}</Typography>
        </div>
    );
}

export default RoadmapPercentage;
