import { BaseStore } from '../Infrastructure';
import { observable, action, computed } from 'mobx';
import * as Cookies from 'js-cookie';
import moment from 'moment';

export class SettingStore extends BaseStore {

    // Be carful!, Javascript will do Boolean("false");  // == true
    @observable private _miniActive: boolean = Cookies.get('miniActive') ? Cookies.get('miniActive').toLowerCase() === 'true' : false;
    @observable private _itOnlyLinksActive: boolean = Cookies.get('itOnlyLinksActive') ? Cookies.get('itOnlyLinksActive').toLowerCase() === 'true' : false;

    @computed get miniActive(): boolean {
        return this._miniActive;
    }

    @action public handleMiniModeToggle = (): void => {
        this._miniActive = !this._miniActive;
        Cookies.set('miniActive', this._miniActive, { expires: 7 /* days*/ });
    };

    @computed get itOnlyLinksActive(): boolean {
        return this._itOnlyLinksActive;
    }

    @action public handleItOnlyLinksModeToggle = (): void => {
        this._itOnlyLinksActive = !this._itOnlyLinksActive;
        Cookies.set('itOnlyLinksActive', this._itOnlyLinksActive, { expires: 7 /* days*/ });
    };

    public get CurrentYear(): number {
        return moment().year();
    }
}

export default SettingStore;
