import { action, observable, runInAction } from 'mobx';
import { SettingsService } from './Middleware/SettingsService';
import BaseStore from './Infrastructure/BaseStore';
import SettingStore from './Layout/SettingStore';
import RoadmapStore from 'src/Roadmap/RoadmapStore';
import { AuthStore, UserProfileStore, SecurityStore } from './Auth';
import HomePageStore from 'src/HomePage/HomePageStore';
import { configureApplicationInsights } from '../Shared/AppInsights';

export class AppStore extends BaseStore {
    // All global stores will be added here, they must be private as they should only be accessible
    // by injecting them into the components where they are required.
    @observable public isSettingLoaded = false;
    public authStore: AuthStore;
    public securityStore: SecurityStore;
    private userProfileStore: UserProfileStore;
    public settingStore: SettingStore;
    private roadmapStore: RoadmapStore;
    private homePageStore: HomePageStore;

    constructor() {
        super();
        this.authStore = new AuthStore();
        this.securityStore = new SecurityStore();
        this.userProfileStore = new UserProfileStore(this.authStore);
        this.settingStore = new SettingStore();
        this.roadmapStore = new RoadmapStore();
        this.homePageStore = new HomePageStore();
    }

    // tslint:disable-next-line:no-any
    public getStores(): Record<string, any> {
        return {
            authStore: this.authStore,
            securityStore: this.securityStore,
            userProfileStore: this.userProfileStore,
            settingStore: this.settingStore,
            roadmapStore: this.roadmapStore,
            homePageStore: this.homePageStore
        };
    }

    @action public async loadAppData(): Promise<void> {
        await SettingsService.loadSettings();

        runInAction(async () => {

            this.isSettingLoaded = true;

            SettingsService.configureCrashReporting();

            this.authStore.init();

            window.authenticate = await this.authStore.login();
            configureApplicationInsights(this.authStore!.getUser);

            if (this.authStore.isAuthenticated) {
                this.authStore.setLoggedInStatus(true);
                await this.securityStore.init();
            }
        });
    }
}

export default AppStore;
